

body {
    background: #F7F7F7;
}
#setup_button {
    display: none;
}
.nav-wrapper {
    background: #FFF;
    color: #444;
}

.nav-wrapper svg {
    color: #444;
    font-size: 24px;
    margin-top: 5px;
}

.nav-wrapper li a {
    color: #444;
}

.container-fluid {
    width: 95%;
    margin: 0 auto;
}

/* TABLE */
td.min-width {
    width: 2% !important;
}
td.min-width-10 {
    width: 10% !important;
}

td.min-width-15 {
    width: 15% !important;
}

.error {
    color: red !important;
}

.required:after {
    content:" *";
    font-size: 1em;
    color: red;
}

a {
    cursor: pointer;
}
.link {
    cursor: pointer;
    color: blue;
}

td, input, textarea, select, option {
    text-transform: uppercase;
}


/* MENU RESPONSIVO */

#menu-responsive {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: red;
}

span.success, span.warning, span.danger, span.primary {
    padding: 5px;
    color: black;
    border-radius: 5px;
}
span.success {
    background: rgb(147, 229, 147);
}
span.warning {
    background: rgb(245, 209, 143);
}
span.danger {
    background: rgb(240, 135, 135);
}
span.primary {
    background: rgb(128, 202, 231);
}
.modal-title {
    font-size: 1.5em;
}
.modal-70w {
    max-width: 70% !important;
}
.modal-90w {
    max-width: 90% !important;
}
.none {
    display: none;    
}
tr.selected {
    background: rgb(135, 178, 235) !important;
}

strong {
    font-weight: bold;
}
.center{
    text-align: center;
}
label {
    font-size: 1.2em;
    color: #555;
}
.w-100 {
    width: 100%;
}
.color-red {
    background: red !important;
}

/* LOGIN */
#login {
    background: rgb(2, 60, 107) url('assets/images/back.png');
    height: 100vh;
    width: 100%;
}
@keyframes loginAnimation{
    from {
        top: 50vh;
        opacity: 0.1;
    }
    to {
        top: 20vh;
        opacity: 0.9;
    }
}

@keyframes logoAnimation{
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.9;
    }
}
#login .row {
    animation: loginAnimation 1s;
    position: absolute;
    left: 5%;
    margin-left: 0;
    margin-right: 0;
    top: 20vh;
    background-color: #F7F7F7;
    width: 90%;
    border-radius: 20px;
}

#login img {
    display: block;
    margin: 0 auto;
    animation: logoAnimation 2s 1s ;
}

#login .row .col{
    margin-top: 20px;
}
#login input {
    text-transform: lowercase;
}

#mobile-nav {
    background: rgb(2, 60, 107) url('assets/images/menu.png');
    background-size: cover;
}
#mobile-nav a{
    color: white;
}

#tag-status {
    max-width: 200px
}
#tag-status span.success, #tag-status span.warning, #tag-status span.danger, #tag-status span.primary{
    display: block;
    border-radius: 0px 5px 5px 0px;
    width: 80%;
    padding-left: 20px;
    box-shadow: 2px 2px 5px #CCC;
}
.box-info {
    color: #444;
    background: #FFF;
    border-radius: 8px;
    margin: 5px;
    height: 100px;
    display: block;
}
.box-info div {
    
}
.box-info .text-lg{
    font-size: 4em;
}
.box-info-one {
    background:#A2A1FC;
}
.box-info-two {
    background: #76DDD1;
}
.box-info-three {
    background: rgb(2, 46, 19);
}
.box-info-one .text-lg{
    font-size: 3em;
}

.MuiGrid-container {
    padding: 10px;
}

/* TYPOGRAFIA */
.detail {
    color: #444;
}

#crud {
    /* width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px; */
}

#crud #content-title {
    background-color: #113C6A;   
    color: white;
    padding: 20px 20px 80px;
    text-align: center;
}

#crud #content {
    background-color: white;
    position: relative;
    top: -60px;
    width: 95%;
    margin: 0 auto;
    border-radius: 15px;
    padding: 5px;
}

.pagination li.active {
    background-color: #113C6A;
}

.nav-wrapper {
    position: fixed !important;
    z-index: 1000;
    width: 100% !important;
    height: 57px !important;
}
#mobile-nav {
    z-index: 2000;
}

/* -Em aberto: azul escuro
- Aguardando aprovação: amarelo 
- Aprovados: verde
- Pendentes: laranja
- Em produção: lilás 
- Em viagem: azul claro
- Finalizado: Cinza
- Cancelado: vermelho */
span.status  {
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 600;
}
.status-open{
    background-color: rgb(7, 7, 104);
    color: #FFF;
}
.status-waiting{
    background-color: yellow;
    color: #222;
}
.status-pending{
    background-color: orange;
    color: #222;
}
.status-approved{
    background-color: green;
    color: #FFF;
}
.status-production{
    background-color: rgb(170, 90, 244);
    color: #222;
}
.status-travel{
    background-color: rgb(17, 17, 177);
    color: #FFF;
}
.status-finished{
    background-color: rgb(103, 103, 103);
    color: #FFF;
}
.status-cancel{
    background-color: red;
    color: #FFF;
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
    border-bottom: none;
}

input:focus{
    border-bottom: none;
}

input, select, textarea {
    padding-left: 10px !important;
}